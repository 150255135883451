import { render, staticRenderFns } from "./dormPie.vue?vue&type=template&id=7dcb6bff&scoped=true&"
import script from "./dormPie.vue?vue&type=script&lang=js&"
export * from "./dormPie.vue?vue&type=script&lang=js&"
import style0 from "./dormPie.vue?vue&type=style&index=0&id=7dcb6bff&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dcb6bff",
  null
  
)

export default component.exports